const _window = $(window);
const body = $('body');

const toggleMegaMenuItem = $('.menu-item--toggle-mega-menu');
let megaMenuTimer;
const megaMenuDelay = 0;



/**
 * Disable click on .menu-item--toggle-mega-menu anchor
 */
/*$('a', toggleMegaMenuItem).on('click', function (e) {
	e.preventDefault();
	return false;
});*/


/**
 * Open submenu
 */
toggleMegaMenuItem.on('click', function (e) {
	e.preventDefault();

	let menuToOpen = 'mega-menu-' + $(this).attr('id').replace('menu-item-', '');

	if ($('#' + menuToOpen).hasClass('mega-menu--active'))
	{
		closeMegaMenu();
	}
	else
	{

		openMegaMenu(menuToOpen, $(this));
	}

	return false;
});


/**
 * Close the menu when over logo and other navigation menu items
 */
/*if (matchMedia('(any-hover: hover)').matches)
{
	$('#logo, .main-navigation__menu .menu-item:not(.menu-item--toggle-mega-menu)')
		.on('mouseenter', function () {

			if (matchMedia('(min-width: 1440px)').matches)
			{
				closeMegaMenu();
			}

		});
}*/

/**
 * Close the menu when leaving the menu, clear timeout when entering the menu
 */

/*
if (matchMedia('(any-hover: hover)').matches)
{
	$('.mega-menu')
		.on('mouseenter', function () {

			clearTimeout(megaMenuTimer);

		})
		.on('mouseleave', function () {

			if (matchMedia('(min-width: 1440px)').matches)
			{
				closeMegaMenu()
			}

		});
}
*/



/**
 * Open the menu
 */
function openMegaMenu(menuToOpen, menuItemActive) {
	clearTimeout(megaMenuTimer);

	closeMegaMenu();

	// after hovering for megaMenuDelay, open the menu
	megaMenuTimer = setTimeout(function () {

		body.addClass('mega-menu-open');

		menuItemActive.addClass('active');

		$('#' + menuToOpen).addClass('mega-menu--active');

	}, megaMenuDelay);
}



/**
 * Close the mega menu clicking a button
 */
$('.close-mega-menu').on('click', function (e) {
	e.preventDefault();

	closeMegaMenu();

	return false;
});



/**
 * Close the menu
 */
export function closeMegaMenu() {
	clearTimeout(megaMenuTimer);

	// close .mega-menu after megaMenuDelay
	megaMenuTimer = setTimeout(function () {

		body.removeClass('mega-menu-open');

		$('.mega-menu').removeClass('mega-menu--active');

		$('.menu-item--toggle-mega-menu').removeClass('active');

		$('.menu-item.current').removeClass('current');

		$('.sub-menu.sub-menu--active').removeClass('sub-menu--active');

	}, megaMenuDelay);
}



/**
 * Toggle mega menu submenus
 */
$('> a', '.mega-menu .menu-item-has-children').on('click', function (e) {
	e.preventDefault();

	const subMenu = $(this).next('.sub-menu');
	const menu = subMenu.parents('.menu');

	// close all open submenus
	$('.sub-menu').removeClass('sub-menu--active');

	// open the selected submenu
	subMenu.addClass('sub-menu--active');

	menu.removeAttr('style');
	menu.css('min-height', subMenu.outerHeight());

	return false;
});


$('.sub-menu').each(function () {
	let parentItemTitle = $(this).prev().text();

	$(this).prepend('<li class="mega-menu__back mega-menu__back--submenu">' + parentItemTitle + '</li>')
});

$('.mega-menu__back:not(.mega-menu__back--submenu)').on('click', function () {
	$('.mega-menu--active').removeClass('mega-menu--active');
});

$('.mega-menu__back--submenu').on('click', function () {
	$('.sub-menu--active').removeClass('sub-menu--active');
});