import Venobox from "../libs/venobox.min";

const _window = $(window);
const body    = $('body');
import {goToByScroll} from './go-to-by-scroll';
import {closeMegaMenu} from "./mega-menu";


/**
 * Sticky buttons
 */
let stickyButtons = gsap.timeline();

stickyButtons.to('.btn--pill', {
	scrollTrigger: {
		trigger:             'footer',
		start:               'top 100%-=-8', // which means "when the top of the trigger hits -8px above the bottom of the viewport (40px button height - 32px bottom position = -8px)
		toggleActions:       'play none none reset',
		invalidateOnRefresh: true,
		//markers:             true
	},
	position:      'absolute',
	bottom:        $('#site-footer').outerHeight() + 32 // footer height + margin bottom
});


/**
 * Tabs
 */
$('span[data-tab]').off('click').on('click', function () {
	const tab = $(this).attr('data-tab');

	$('[data-tab="' + tab + '"]', '.tabs__header').addClass('active').siblings().removeClass('active');

	if (tab === 'all')
	{
		$('div[data-tab]', '.tabs__tabs').addClass('active');

		const currentURL = window.location.href;

		if (window.location.hash)
		{
			// remove hash from url
			window.history.pushState("", document.title, currentURL.split('#')[0]);
		}
	}
	else
	{
		const activeItem = $('div[data-tab="' + tab + '"]', '.tabs__tabs');
		activeItem.addClass('active');

		let inactiveItems = activeItem.siblings();
		inactiveItems.removeClass('active');
	}

	goToByScroll($('.tabs'), -16);
});


body.on('click.smoothscroll', 'a[href*="#tab-"]', function (e) {
	setTimeout(function () {
		let tab = location.hash.split('tab-');

		let target = $('span[data-tab="' + tab[1] + '"]');

		if (target.length > 0)
		{
			target.trigger('click');

			closeMegaMenu();
		}
	}, 10);
});


_window.on('load', function (event) {

	// smooth scroll to tab on load
	if (location.hash.includes('tab'))
	{
		let tab = location.hash.split('tab-');

		let target = $('span[data-tab="' + tab[1] + '"]');

		if (target.length > 0)
		{
			target.trigger('click');
		}
	}

});


if ($('.hero-foglia').length > 0)
{
	gsap.to(".hero-foglia", {
		opacity:       0,
		ease:          "none",
		scrollTrigger: {
			trigger: '.hero-foglia',
			start:   0,
			end:     $('.hero-foglia').height(),
			scrub:   true,
			//markers: true
		}
	});
}

new Venobox({
	selector:     '.venobox-3d',
	numeration:   false,
	overlayColor: 'rgba(0,0,0,0.85)',
	bgcolor:      'transparent',
	//maxWidth: '95%',
	onPostOpen: function (obj, gallIndex, thenext, theprev) {
		resizeIframe3d();
	},
});


/**
 * Resize iframe inside venobox based on viewport size maintaining 16/9 aspect ratio
 */
function resizeIframe3d() {
	let iframe3d = $('iframe', '.vbox-overlay.iframe-3d');

	if (_window.width() / _window.height() > 1.7777)
	{
		iframe3d.css('width', iframe3d.outerHeight() * 1.777777);
	}
}


/**
 * Calculate true viewport height without toolbars on mobile
 */
function calculateViewportHeight() {
	document.documentElement.style.setProperty('--viewport-height', window.visualViewport.height + 'px');
	document.documentElement.style.setProperty('--viewport-width', window.visualViewport.width + 'px');
}

calculateViewportHeight();

visualViewport.addEventListener('resizeEnd', () => {
	calculateViewportHeight();
});


/**
 * Custom checkboxes in WP Members forms
 */
$('form[id*="wpmem"] input[type=checkbox], form[id*="wpmem"] input[type=radio]').inputToToggle().each(function () {
	let id = $(this).attr('id');

	$(this).next('.toggle-control').wrap('<label for="' + id + '"></label>');
});


/**
 * Move buttons on mobile
 * @type {MediaQueryList}
 */
const breakpointMegamenu = window.matchMedia('(min-width: 992px)');

const breakpointMegamenuChecker = function () {

	// if larger viewport
	if (breakpointMegamenu.matches)
	{
		// remove the buttons from #site-navigation, insert them after #site-navigation and remove them from #mega-menu-user
		let headerButtons = $('.btn-appointment, .btn-profile', '#site-navigation').detach();
		$('#site-navigation').after(headerButtons);
		//$('.menu-buttons-mobile', '#mega-menu-user').empty();
	}
	// else if a small viewport
	else
	{
		// remove the buttons from the header and append them to #site-navigation and #mega-menu-user
		let headerButtons = $('.btn-appointment, .btn-profile', '#header').detach();
		headerButtons.appendTo([
			$('.menu-buttons-mobile', '#site-navigation'),
			//$('.menu-buttons-mobile', '#mega-menu-user')
		]);
	}
};

breakpointMegamenu.addEventListener('change', breakpointMegamenuChecker);

breakpointMegamenuChecker();


/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {

});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const resizeEndEvent = new Event('resizeEnd');
			window.dispatchEvent(resizeEndEvent);
			visualViewport.dispatchEvent(resizeEndEvent);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {

	resizeIframe3d();

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/