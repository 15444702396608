import {utils} from "./utils";

/**
 * Filters
 * @type {Element}
 */

const filterForm = document.querySelector('.filters-form');

const filters = document.querySelectorAll('.filter');


const filterValues = document.querySelectorAll('.filter .btn');

filterValues.forEach((el) => {
	el.addEventListener('click', function () {
		const filterValue = this;
		const filter = utils.parents(filterValue, '.filter')[0];
		const selectedValue = filterValue.dataset.value;

		const select = filter.querySelector('select');
		const selectedOption = select.querySelector('option[value="' + selectedValue + '"]');

		if (selectedOption.selected === true)
		{
			selectedOption.selected = false;
			filterValue.classList.remove('selected');
		}
		else
		{
			selectedOption.selected = true;
			filterValue.classList.add('selected');
		}

		//filterForm.submit();
	});
});

const filtersReset = document.querySelector('.reset-filters');

if (filtersReset !== null)
{
	filtersReset.addEventListener('click', function () {
		const selects = filterForm.querySelectorAll('select');

		selects.forEach((select) => {
			select.selectedIndex = -1;
		});

		const inputs = filterForm.querySelectorAll('input[type=text]');

		inputs.forEach((input) => {
			input.value = '';
		});

		filterForm.submit();
	});
}

const filtersCancel = document.querySelectorAll('.filter__cancel');

filtersCancel.forEach((el) => {
	el.addEventListener('click', function () {
		document.querySelector('.filter--open').classList.remove('filter--open');

		setTimeout(function () {
			document.body.classList.remove('filter-open');
		}, 300);
	});
});